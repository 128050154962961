import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { restRequest } from "../../../../api-utils/apiQueries";
import { Edit3, Eye, Search, Trash2 } from "react-feather";
import AddNewTags from "./AddNewTags";
import {
	Button,
	Card,
	CardBody,
	Col,
	Input,
	Label,
	Media,
	Row,
	Tooltip,
} from "reactstrap";
import { toast } from "react-toastify";

const TagsWrapper = ({ mainTitle }) => {
	const [services, setServices] = useState([]);
	const [tags, setTags] = useState([]);
	const [subCategoryOption, setSubCategoryOption] = useState([]);
	const [totalPage, setTotalPage] = useState();
	const [page, setPage] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [isUpdated, setIsUpdated] = useState(true);
	const [selectedService, setSelectedService] = useState(null);
	const [totalSubCategories, setTotalSubCategories] = useState(0);
	const [tooltipOpen, setTooltipOpen] = useState({});

	const toggleTooltip = (index) => {
		setTooltipOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const toggle = () => {
		setShowModal(!showModal);
		setSelectedService(null);
	};
	const [searchValue, setSearchValue] = useState("");
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState(0);

	const updatedCategory = (row) => {
		setShowModal(true);
		setSelectedService(row);
	};

	const handleSort = (column, sortDirection) => {
		console.log("Column Data ->", column.id);
		setSortDirection(sortDirection === "desc" ? 0 : 1);
		setSortColumn(column.id);
		setIsUpdated(true);
	};

	const handleSearchValue = (e) => {
		let data = e.target.value;
		setSearchValue(data);
	};

	const handleSwitch = async (tag, type) => {
		if (type === "is_active") {
			let data = {
				subCategoryId: tag.subCategory_id,
				name: tag.name,
				is_active: !tag[type]
			};
			let response = await restRequest.updateTags(tag?._id, data);
			if (response.data) {
				toast.success(response.data.message);
			}
			setIsUpdated(true);
		}
	};

	const tableColumns = [
		{
			name: "Tag",
			selector: (row) => row.name,
			id: "name",
			cell: (row) => (
				<span className="text-secondary fw-medium">{row.name}</span>
			),
			sortable: true,
		},
		{
			name: "Sub Category",
			selector: (row) => row.name,
			id: "name",
			cell: (row) => (
				<span className="text-secondary fw-medium">{row.sub_category_name}</span>
			),
			sortable: true,
		},
		// {
		// 	name: "Category",
		// 	selector: (row) => row.name,
		// 	id: "name",
		// 	cell: (row) => (
		// 		<span className="text-secondary fw-medium">{row.category_name}</span>
		// 	),
		// 	sortable: true,
		// },

		{
			name: "Status",
			selector: (row) => row.is_active,
			id: "is_active",
			cell: (row) => (
				<span
					className={`p-1 px-2 rounded-3 ${row.is_active ? "bg-success" : "bg-danger"
						}`}
					style={{ color: "" }}
				>
					{row.is_active ? "active" : "inactive"}
				</span>
			),
			sortable: true,
		},
		{
			name: "Created At",
			selector: (row) => row.createdAt,
			id: "createdAt",
			cell: (row) => (
				<span className="text-secondary fw-medium">
					{new Date(row.createdAt).toLocaleDateString()}
				</span>
			),
			sortable: true,
		},
		{
			name: "Updated At",
			selector: (row) => row.updatedAt,
			id: "updatedAt",
			cell: (row) => (
				<span className="text-secondary fw-medium">
					{new Date(row.updatedAt).toLocaleDateString()}
				</span>
			),
			sortable: true,
		},
		{
      name: "Status Toggle",
      selector: (row) => row.is_active,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.is_active}
                onChange={() => handleSwitch(row, "is_active")}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Trending </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
		{
			name: "Action",
			sortable: false,
			selector: "null",
			cell: (row) => (
				<>
					<button
						onClick={() => updatedCategory(row)}
						className="border border-none rounded text-success me-2"
					>
						<Edit3 />
					</button>
				</>
			),
		},
	];

	const fetchtags = async () => {
		let response = await restRequest.getAllTags(
			page,
			sortDirection,
			sortColumn,
			searchValue
		);
		let data = response.data.data;
		console.log("Response ->sub-cat", data);
		setTags(data.tags);
		setTotalPage(data.pager.totalPages);
		setTotalSubCategories(data.pager.totalRecords);
		setIsUpdated(false);
	};
	// console.log("IS UPDATED ->", isUpdated);

	useEffect(() => {
		fetchServices();
	}, [])

	useEffect(() => {
		if (isUpdated) fetchtags();
	}, [isUpdated, page]);

	const handlePageChange = (page) => {
		setPage(page);
		setIsUpdated(true);
	};

	const fetchServices = async () => {
		let response = await restRequest.getSubCategoryOption()
		const data = response.data.data;
		if (data.length) {
			setSubCategoryOption(data);
		}
	};

	const paginationComponentOptions = {
		rowsPerPageText: "Rows per page:",
		rangeSeparatorText: "of",
		noRowsPerPage: true,
		selectAllRowsItem: false,
	};
	// console.log("Show modal ->", showModal);

	return (
		<Fragment>
			<Row>
				<div className="d-flex">
					<Breadcrumbs mainTitle={`Tags Services (${totalSubCategories})`} />
					<div className="d-flex gap-4">
						<div
							className={`input-group left-header horizontal-wrapper`}
							style={{ width: "300px" }}
						>
							<Input
								type="text"
								placeholder="Search.."
								value={searchValue}
								onChange={handleSearchValue}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										setSearchValue(e.target.value);
										setIsUpdated(true);
									}
								}}
							/>
							<span className="input-group-text mobile-search">
								<Search
									onClick={() => {
										setIsUpdated(true);
									}}
								/>
							</span>
						</div>
						<Btn
							attrBtn={{
								color: "primary",
								className: "d-flex w-[120px]",
								onClick: () => setShowModal(true),
							}}
						>
							<p className="mb-0 fs-7" style={{ width: "120px" }}>
								Add New Tags
							</p>
						</Btn>
					</div>
				</div>
				<Col>
					<Card>
						<CardBody className="data-tables">
							<DataTable
								data={tags}
								columns={tableColumns}
								striped={true}
								center={true}
								pagination
								// selectableRows
								paginationServer
								paginationTotalRows={totalSubCategories}
								onChangePage={handlePageChange}
								paginationPerPage={20}
								paginationComponentOptions={paginationComponentOptions}
								onSort={handleSort}
								sortServer
							// onSelectedRowsChange={handleRowSelected}
							// clearSelectedRows={toggleDelet}
							/>
						</CardBody>
					</Card>
				</Col>
				{showModal && (
					<AddNewTags
						showModal={showModal}
						toggle={toggle}
						selectedService={selectedService}
						setIsUpdated={setIsUpdated}
						subCategoryOption={subCategoryOption}
					/>
				)}
			</Row>
		</Fragment>
	);
};

export default TagsWrapper;

import React, { Fragment, useEffect, useState } from "react";
import {
	Form,
	FormGroup,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Toast,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { restRequest } from "../../../../api-utils/apiQueries";
import { toast } from "react-toastify";
import SelectField from "../../../../CommonElements/Select/SelectField";

const AddNewTags = ({
	showModal,
	toggle,
	selectedService,
	setIsUpdated,
	subCategoryOption,
}) => {

	const [tagName, setTagName] = useState(selectedService ? selectedService.name : "");
	const [selectedCategories, setSelectedCategories] = useState(selectedService ? [{value: selectedService.subCategory_id, label:selectedService.sub_category_name}] :[]);
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		let response = {};
		try {
			if (selectedService?._id) {
				const updatePayload = {
					subCategoryId: selectedService.subCategory_id,
					name: tagName.split(', ')[0],
				}
				response = await restRequest.updateTags(selectedService?._id, updatePayload);
			} else{ 
				const payload = {
					subCategoryId: selectedCategories.value,
					tagNames: tagName.split(', '),
				};
				response = await restRequest.addTagsService(payload);
			}
		} catch (error) {
			console.warn(error);
		} finally {
			if (response.data) {
				setIsUpdated(true);
				toast.success(response.data.message);
				toggle();
			}
		}
		
	};

	const handleTagNameChange = (e) => {
		const value = e.target.value;
		setTagName(value);
	}

	return (
		<Fragment>
			<Modal isOpen={showModal} toggle={toggle} size="md">
				<ModalHeader toggle={toggle} className="pt-4">
					<span className="fs-5 text-#534686">
						{" "}
						{selectedService
							? "Update Tag Service"
							: "Add New Tag Service"}{" "}
					</span>
				</ModalHeader>
				<ModalBody>
					<Form
						className="form-bookmark needs-validation"
						onSubmit={handleSubmit}
					>
						<div
							className="form-row h-50 overflow-auto"
							style={{ height: "700px", overflowY: "auto" }}
						>
							<FormGroup className="col-md-12">
								<label className="text-dark-md pb-1">Sub Categories </label>
								<SelectField
									options={subCategoryOption}
									selectedOption={selectedCategories}
									setSelectedOption={setSelectedCategories}
									isMulti={false}
								/>
							</FormGroup>
							<FormGroup className="col-md-12">
								<Label>Tags Name</Label>
								<textarea
									className="form-control"
									value={tagName}
									onChange={handleTagNameChange}
									rows="6" 
									cols="50"
									placeholder="Enter your Tags here..."
								/>
								{/* <span style={{ color: "red" }}>
                  {errors.url && "Url is required"}
                </span> */}
							</FormGroup>
						</div>
						<div className="d-flex gap-4 justify-content-end">
							<Btn
								attrBtn={{
									color: "secondary ms-2",
									onClick: toggle,
								}}
							>
								Cancel
							</Btn>
							{/* {selectedService ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )} */}
							{ selectedService ? (
								<Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
							) : (
								<Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
							)}
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</Fragment>
	);
};

export default AddNewTags;

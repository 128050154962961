import React, { Fragment, useState, useEffect } from "react";
import { ScaleLoader } from "react-spinners";

const Loader = () => {
  const [show, setShow] = useState("flex");
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow("none");
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return (
    <Fragment>
      {/* <div className='loader-wrapper' style={{ display: `${show}` }}>
                <div className="loader"></div>
            </div> */}

      <div
        className="loader-wrapper"
        style={{ display: `${show}` }}
      >
        <ScaleLoader
          color="#472d96"
          height={60}
          width={15}
          radius={5}
          margin={5}
        />
      </div>
    </Fragment>
  );
};

export default Loader;

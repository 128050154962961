import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const animatedComponents = makeAnimated();

const SelectField = ({
  options,
  selectedOption,
  getData,
  handleOptionCreate,
  setSelectedOption,
  isMulti=true
}) => {
  const handleOptionValue = (inputValue) => {
    if(getData) getData(inputValue);
    return inputValue;
  };

  return (
    <CreatableSelect
      isMulti={isMulti}
      components={animatedComponents}
      options={options}
      value={selectedOption}
      onInputChange={handleOptionValue}
      onChange={(selectedOption) => setSelectedOption(selectedOption)}
      onCreateOption={handleOptionCreate}
      isSearchable={true}
      isClearable
    />
  );
};

export default SelectField;
